import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './characters.scss';
import { Card, Col, Row } from 'react-bootstrap';
import lodash from 'lodash';
import { IZZZBangboo } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { ZZZBangboo } from '../../modules/zzz/common/components/zzz-bangboo';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';

interface IZZZBangbooNodes {
  nodes: IZZZBangboo[];
}

interface IZZZBangbooEntry {
  allCharacters: IZZZBangbooNodes;
}

interface IProps {
  data: IZZZBangbooEntry;
}

const ZZZBangbooPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search Bangboo...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'A',
            tooltip: 'A Rank',
            image: (
              <StaticImage
                src="../../images/zzz/icons/rarity_a.png"
                width={24}
                alt="A"
              />
            )
          },
          {
            value: 'S',
            tooltip: 'S Rank',
            image: (
              <StaticImage
                src="../../images/zzz/icons/rarity_s.png"
                width={24}
                alt="S"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke characters-zzz '}
      game="zzz"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>Bangboo List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/zzz/categories/category_bangboo.png"
            alt="Bangboo"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero Bangboo List</h1>
          <h2>
            Bangboo are 'pets' in Zenless Zone Zero that help your team - find
            every Bangboo available in the game below!
          </h2>
          <p>
            Last updated: <strong>14/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Bangboo"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} Bangboo
          </span>
        }
      />
      <div className="employees-filter-bar zzz">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className={`employees-container zzz-cards`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <ZZZBangboo slug={emp.slug} mode="card" showLabel />
              </Card>
            </span>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/zzz/no_results.webp"
            alt="No results"
          />
          <p>No Bangboo found. Try changing your filter.</p>
        </div>
      )}
      <SectionHeader title="Bangboo guide" />
      <Row className="video">
        <Col xs={12} lg={6}>
          <YoutubeEmbed embedId="GV0BKl7EbkM" />
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default ZZZBangbooPage;

export const Head: React.FC = () => (
  <Seo
    title="Bangboo | Zenless Zone Zero | Prydwen Institute"
    description="Bangboo are 'pets' in Zenless Zone Zero that help your team - find every Bangboo available in the game below!"
    game="zzz"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulZzzBangboo(sort: { fields: name, order: ASC }) {
      nodes {
        id
        unitId
        slug
        name
        rarity
      }
    }
  }
`;
